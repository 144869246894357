import { useEffect, useState } from 'react'
import path from 'path'
import axios from 'axios'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './App.css'

dayjs.extend(relativeTime)

function App() {
  const apiURL = "http://cloud.magnanix.io:8082"
  const [similars, setSimilars] = useState([])
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [fetching, setFetching] = useState(false)
  const [progress, setProgress] = useState(0)
  const [progressTotal, setProgressTotal] = useState(0)
  const tomorrow = dayjs().add(1, 'd')

  async function progressURL(url){
    setFetching(true)
    setProgressTotal(0)
    let headers = new Headers({
        'Authorization': `Basic ${btoa(user + ':' + password)}`
    });
    try {
      const response = await fetch(`${url}`, {headers: headers})
      const reader = response.body.getReader()
      const decoder = new TextDecoder()
  
      while (true){
        const {done, value} = await reader.read()
        if (done) break
        //console.log(`${progress} / ${progressTotal}`)
        let text = decoder.decode(value)
        if(progressTotal === 0 && text.includes('total')){
          let lines = text.split(/\r?\n/)
          setProgressTotal(lines[0].split(' ')[1])
        }else{
          let lines = text.split(/\r?\n/)
          setProgress(lines[lines.length-1])
        }
      }
      if(response.ok){
        setProgressTotal(0)
        setFetching(false)
      }
    }catch(error){
      console.log("There was an error!", error)
    }
  }

  async function obtainLibrary() {
    await progressURL(`${apiURL}/api/library/obtain`)
  }

  async function updateLibrary() {
    await progressURL(`${apiURL}/api/library/update`)
  }

  async function updateSimilar() {
    await progressURL(`${apiURL}/api/library/update-similar`)
    loadSimilar()
  }

  async function downloadImages() {
    await progressURL(`${apiURL}/api/library/images`)
    loadSimilar()
  }

  function loadSimilar() {
    axios.get(`${apiURL}/api/library/similar`)
    .then(res => {
      setSimilars(res.data)
    })
    .catch(err => {
      console.log("No similars available...")
    })
  }

  useEffect(() => {
    loadSimilar()
  }, [])

  return (
    <section className="flex flex-col justify-center antialiased bg-gray-100 text-gray-600 min-h-screen p-4">
      <div className="h-full">
          <div className="w-full mx-auto bg-white shadow-lg rounded-sm border border-gray-200">
              <div className="text-center mx-auto pt-6 px-6">
                {(!user)&&(<input className="mb-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" placeholder="Username" aria-label="Username" onBlur={(e) => {setUser(e.target.value)}} />)}
                {(!password)&&(<input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="password" placeholder="Password" aria-label="Password"  onBlur={(e) => {setPassword(e.target.value)}} />)}
                {(user && password) && (
                  <form>
                    <button className="mx-2 bg-blue-300 hover:bg-gray-400 disabled:bg-gray-100 disabled:cursor-wait text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" onClick={obtainLibrary} disabled={fetching}>
                      <span>Obtain</span>
                    </button>
                    <button className="mx-2 bg-blue-300 hover:bg-gray-400 disabled:bg-gray-100 disabled:cursor-wait text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" onClick={updateLibrary} disabled={fetching}>
                      <span>Update Library</span>
                    </button>
                    <button className="mx-2 bg-blue-300 hover:bg-gray-400 disabled:bg-gray-100 disabled:cursor-wait text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" onClick={updateSimilar} disabled={fetching}>
                      <span>Check Similars</span>
                    </button>
                    <button className="mx-2 bg-blue-300 hover:bg-gray-400 disabled:bg-gray-100 disabled:cursor-wait text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" onClick={downloadImages} disabled={fetching}>
                      <span>Download Images</span>
                    </button>
                  </form>
                )}
                {(progressTotal > 0) && (
                  <>
                    <div className="mb-1 mx-auto text-base font-medium dark:text-white">{progress + "/" + progressTotal}</div>
                    <div className="w-full h-6 bg-gray-200 rounded-full dark:bg-gray-700">
                      <div className="h-6 bg-blue-600 rounded-full dark:bg-blue-500" style={{width: `${Math.floor((progress/progressTotal)*100)}%`}}></div>
                    </div>
                  </>
                )}
              </div>
              <header className="px-5 py-4 border-b border-gray-100">
                  <h2 className="font-semibold text-gray-800">Upcoming Books</h2>
              </header>
              <div className="p-3">
                  <div className="overflow-x-auto">
                      <Table>
                          <Thead>
                              <Tr>
                                  <Th>
                                      <div className="font-semibold text-left">Book</div>
                                  </Th>
                                  <Th>
                                      <div className="font-semibold text-left">&nbsp;</div>
                                  </Th>
                                  <Th>
                                      <div className="font-semibold text-left">Release Date</div>
                                  </Th>
                              </Tr>
                          </Thead>
                          <Tbody>
                            {similars.map((book, index) => {
                              let file = '/images/'+path.basename(book.image_url)
                              return (
                                <Tr key={index} className={`${((dayjs(book.release_date).diff(tomorrow) > 0)&&'bg-red-50')||'bg-green-50'}`} >
                                  <Td className="p-2 whitespace-nowrap">
                                      <div className="flex items-center">
                                          <div className="flex-shrink-0 mr-2 sm:mr-3"><a href={"https://audible.com/pd//"+book.asin} target="_blank" rel="noreferrer" className="text-indigo-600 hover:text-indigo-900"><img className="rounded" src={file} onError={(e) => {e.target.onError = null; e.target.src = '/placeholder.png';}} width="128" height="128" alt={book.title} /></a></div>
                                      </div>
                                  </Td>
                                  <Td className="p-2 whitespace-nowrap">
                                      <div className="font-bold text-gray-800"><a href={"https://audible.com/pd//"+book.asin} target="_blank" rel="noreferrer">{book.title}</a></div>
                                      {!!book.subtitle&&(<div className="text-gray-700 text-xs">{book.subtitle}</div>)}
                                      {!!book.name&&(<div className="text-gray-500 text-xs mt-1">{book.name}</div>)}
                                  </Td>
                                  <Td className={`${((dayjs(book.release_date).diff(tomorrow) > 0)&&'text-red-500')||'text-green-500'} p-2 whitespace-nowrap`}>
                                      <div className="font-bold text-left font-medium capitalize">{dayjs(book.release_date).fromNow()}</div>
                                      <div className="text-left font-medium capitalize">{book.release_date}</div>
                                  </Td>
                                </Tr>
                              )
                            })}
                          </Tbody>
                      </Table>
                  </div>
              </div>
          </div>
      </div>
    </section>
  );
}

export default App;
